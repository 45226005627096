@import url(https://fonts.googleapis.com/css?family=Lato:400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700,900i);
:root {
  --main: #202329;
  --main-dark: #15171b;
  --main-darker: #0A0B0C;
  --main-lighter: #30353e;

  --accent: #0084ff;
  --accent-lighter: #80C1FF;

  --gray-lightest: #f0f0f0;
  --gray-lighter: #d3d3d3;
  --gray-light: #959595;
  --gray: #565656;
  --gray-dark: #333;
  --gray-darker: #171717;

  --success: #18bc9c;
  --error: #e74c3c;

  --twitch: #6441a5;
  --youtube: #e52d27;
  --hitbox: #99CC00;

  --live: #d2322d;

  --finished: #229e45;
  --finished-light: #F9FCFA;

  --victory: #5cb85c;
  --defeat: #d2322d;
  --draw: #959595;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #202329;
  -webkit-font-smoothing: antialiased;
  background: #f0f0f0;
  background: var(--gray-lightest);
  overflow-y: scroll;
}

a {
  text-decoration: none;
}

.PageContainer {
  padding: 20px;
}

.TableContainer {
  /* overflow: scroll; */
}

@media screen and (max-width: 768px) {
  .TableContainer table {
    min-width: 700px;
  }
}


.AddTop {
  margin-top: 16px;
}

.AddBottom {
  margin-bottom: 16px;
}

.AddRight {
  margin-right: 16px;
}

.TextField {
  margin-top: -12px;
}


.Label {
  font-size: 13px;
  color: #0084ff;
  color: var(--accent);
  display: block;
  margin-bottom: 8px;
}
.LoaderContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.LoaderContainer__loader {
  color: var(--accent);
  font-size: 5px;
  margin: 50px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.LoadMask__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.75);
  overflow: auto;
  color: white;
}

/* LoadMask__backdrop transitions */
.LoadMask-appear.LoadMask__backdrop {
  opacity: 0;
}

.LoadMask-appear.LoadMask-appear-active.LoadMask__backdrop {
  opacity: 1;
  transition: opacity 100ms ease-in;
}
/* LoadMask__backdrop transitions END */
.Login {
  background: var(--main-dark);
  height: 100vh;
  padding: 60px;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
}

.Login__box {
  width: 100%;
  border-radius: 20px;
  background: var(--main);
  box-shadow: 0 8px 20px 0 rgba(32, 35, 41, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login__form {
  padding: 30px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 8px 20px 0 rgba(32, 35, 41, 0.6);
  width: 500px;
}

.Dashboard__title {
  font-family: 'Segoe UI';
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
}

.Login__form-logo {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.Login__form-sentence {
  display: block;
  margin-top: 16px;
  color: var(--gray-light);
  font-style: italic;
}

.Login__steam-btn {
  display: block;
  margin-top: 16px;
}


.Alert {
  position: relative;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  align-items: center;
  display: flex;
  z-index: -1;
  box-shadow: 3px 0 6px rgba(0,0,0,0.1);
  white-space: pre-wrap;
}

.Alert__Warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.Alert__Warning-Icon {
  margin-right: 8px;
  font-size: 1.5rem;
  line-height: 1;
}

.Alert__Info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.Alert__Error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.Alert__Icon {
  margin-right: 8px;
  font-size: 1.5rem;
  line-height: 1;
}

@media screen and (max-width: 1150px) {
  .Alert {
      display: block;
      font-size: 12px;
      line-height: 1.5;
      padding: 0.75rem 0.75rem;
  }

  .Alert__Icon {
      font-size: 1rem;
      margin-right: 4px;
  }
}
.Header {
  background: white;
  top: 0;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
}

.Header__toggle-menu {
  display: none;
}

.Header__page-name {
  color: var(--accent);
  margin: 0;
  flex-grow: 2;
}

.Header__actions {
  margin: 0 24px;
}

.Header__login-info {
  display: flex;
  align-items: center;
}

.Header__logout-icon {
  margin-left: 8px;
  color: var(--gray);
  cursor: pointer;
}

.Header__login-info-avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.Header__login-info-username {
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  top: -1px;
}

@media screen and (max-width: 768px) {
  .Header__toggle-menu {
    display: inline-block;
    cursor: pointer;
    margin-right: 16px;
  }
}
.Card {
  background: white;
  box-shadow: 0 0px 35px rgba(50,50,93,.01), 0 5px 15px rgba(0,0,0,.07);
  padding: 20px;
}

.Card--no-padding {
  padding: 0px;
}

.Card__title {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 8px;
  padding-left: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--gray-lighter);
  position: relative;
}

.Card__title:before {
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: var(--accent);
  position: absolute;
  display: block;
  top: 6px;
  left: 2px;
}

.Card--no-padding .Card__title {
  padding: 16px;
  padding-left: 32px;
}

.Card--no-padding .Card__title:before {
  top: 22px;
  left: 16px;
}
.Table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0px 35px rgba(50,50,93,.01), 0 5px 15px rgba(0,0,0,.07);
}

.Table__thead {
  background: var(--gray-lighter);
  box-shadow: inset 0 -5px 8px rgba(0,0,0,0.1);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 18px;
}

.Table__thead tr td {
  padding: 5px;
}

.Table__thead tr td:last-child {
  border-right: 0px;
}

.Table__tbody {
  background: white;
  font-size: 14px;
}

.Table__tbody tr td {
  padding: 10px;
}

.Table__tbody tr {
  border-bottom: 1px solid var(--gray-lighter);
}

.Table__tbody tr:last-child {
  border-bottom: 0px;
}
.Button {
  width: auto;
  border: 0;
  border-radius: 2px;
  padding: 16px 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Fira Sans', sans-serif;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.01), 0 5px 15px rgba(0, 0, 0, 0.07);
  position: relative;
  top: 0;
}

.Button[disabled] {
  opacity: 0.5;
}

.Button:hover {
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.2);
  top: -2px;
}

.Button__icon {
  display: inline-block;
  margin-right: 8px;
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.Button--outline {
  background: transparent;
  border: 1px solid var(--accent);
  color: var(--accent);
}

.Button--block {
  width: 100%;
}

/* ========================
SKINS
========================= */
.Button--main {
  background: var(--main);
  color: white;
  border-color: var(--main);
}

.Button--accent {
  background: var(--accent);
  color: white;
  border-color: var(--accent);
}

.Button--gray {
  background: var(--gray);
  color: white;
  border-color: var(--gray);
}

.Button--gray.Button--outline {
  background: transparent;
  color: var(--gray);
  border-color: var(--gray);
}

.Button--error {
  background: var(--error);
  color: white;
  border-color: var(--error);
}

.Button--error.Button--outline {
  border-color: var(--error);
  background: transparent;
  color: var(--error);
}

.Button--success {
  background: var(--success);
  color: white;
  border-color: var(--success);
}

/* ========================
Sizes
========================= */
.Button--small {
  padding: 8px;
  font-size: 14px;
}

.Button--micro {
  padding: 4px;
  font-size: 12px;
}

.Button--micro .Button__icon {
  font-size: 14px;
  margin: 0;
  margin-right: 4px;
  height: auto;
  width: auto;
}

.Button--disabled,
.Button[disabled] {
  color: white;
  opacity: 0.5;
  background: var(--gray-light);
  cursor: not-allowed;
}

.Modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0;
}

/* Modal__backdrop transitions */
.Modal-appear.Modal__backdrop {
  opacity: 0;
}

.Modal-appear.Modal-appear-active.Modal__backdrop {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.Modal-exit.Modal__backdrop {
  opacity: 1;
}

.Modal-exit.Modal-exit-active.Modal__backdrop {
  opacity: 0;
  transition: opacity 400ms ease-in;
}
/* Modal__backdrop transitions END */

.Modal__window {
  /* margin: auto; */
  z-index: 3;
  height: auto;
  width: 40%;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 var(--box-shadow);
  opacity: 1;
  background-color: white;
  text-align: left;
}

/* Modal__window transitions */
.Modal-appear .Modal__window {
  opacity: 0;
}

.Modal-appear.Modal-appear-active .Modal__window {
  opacity: 1;
  transition: opacity 400ms ease-in;
}
/* Modal__window transitions END */

.Modal__title {
  padding: 24px;
  font-size: 16px;
  font-weight: bold;
  color: var(--accent);
  border-bottom: 1px solid var(--gray-lighter);
}

.Modal__content {
  padding: 24px;
}

.Modal__footer {
  padding: 24px;
  border-top: 1px solid var(--gray-lighter);
  display: flex;
  justify-content: flex-end;
}
.MatchList__SearchBar {
  display: flex;
  justify-content: right;
  margin-bottom: 16px;
  align-items: center;
}

.MatchList__SearchBar small {
  display: block;
  margin-right: 8px;
}

.MatchList__label {
  color: white;
  padding: 3px 4px;
  font-size: 10px;
  border-radius: 5px;
  margin-left: 3px;
  font-weight: bold;
}

.MatchList__featured-label {
  background: var(--accent);
}

.MatchList__hidded-label {
  background: #808080;
}

.MatchList__finished-label {
  background: var(--finished);
}

.MatchList__pbp-label {
  background: #d69d02;
}

.MatchList__live-label {
  background: var(--live);
}

.MatchList__nostreamed-label {
  background: #6f6785;
}

.MatchList__istba-label {
  background: #856767;
}

.MatchList__vzone-label {
  background: #101822;
}


.MatchList__football-label {
  background: #2b9e4e;
}
.NewMatchForm__checkbox {
  margin-bottom: 8px;
}

.NewMatchForm__card {
  margin-bottom: 16px;
}

.NewMatchForm__divider {
  margin: 16px 0;
  border-top: 0px;
  border-bottom: 1px solid var(--gray-lighter);
  height: 0px;
}

.NewMatchForm__text-field {
  margin-top: -14px;
}

.NewMatchForm__label {
  font-size: 12px;
  color: var(--gray-light);
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.NewMatchForm__label--dark {
  color: var(--gray-dark);
}

.NewMatchForm__label--button {
  cursor: pointer;
  font-size: 24px;
}

.NewMatchForm__label--button:hover {
  color: var(--error);
}

.NewMatchForm__preset-btn {
  margin-right: 8px;
}

.NewMatchForm__particularity-presets {
  margin-top: 16px;
}

.NewMatchForm__particularity-preset-btn {
  margin-bottom: 6px;
  text-align: left;
  display: block;
}

.NewMatchForm__emptyCard {
  text-align: center;
  color: #666;
}

.react-mde .mde-tabs {
  display: none;
}
.EventForm__card {
  margin-bottom: 16px;
}

.EventForm__media-btn {
  margin-right: 8px;
}

.EventForm__field-icon-container {
  display: flex;
  align-items: center;
}

.EventForm__field-icon-container div + div {
  margin-left: 16px;
}

.EventForm__field-icon-container .fa {
  margin-right: 8px;
  position: relative;
  top: 13px;
}

.EventForm__label-presets {
  display: flex;
  flex-wrap: wrap;
}

.EventForm__label-presets button {
  margin-right: 8px;
}
.TwitchClip__not-visible {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  color: white;
  font-size: 80px;
  cursor: pointer;
  background-position: center;
  transition: all 0.2s;
}

.TwitchClip__not-visible .fa {
  transition: all 0.2s;
}

.TwitchClip__not-visible:hover .fa {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  text-shadow: 0px 0px 50px rgba(0,0,0,0.9);
}
.MatchEvent {
  position: relative;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
}

.MatchEvent:nth-child(even) {
  background: #f5f5f5;
}

.MatchEventLabel {
  text-align: right;
  padding-right: 20px;
  flex-basis: 15%;
}

.MatchEventLabel p {
  margin: 0;
  line-height: 16px;
  font-size: 20px;
  font-weight: bold;
  color: var(--gray)
}

.MatchEventLabel.MatchEventLabel--custom p {
  font-size: 12px;
}

.MatchEventLabel__map {
  font-size: 12px;
  color: var(--gray-light)
}

.MatchEventContent {
  border-left: 1px solid var(--gray-lighter);
  padding-left: 20px;
  flex-basis: 85%;
}

.MatchEventContent h3 {
  margin: 5px 0 2px 0;
  text-transform: uppercase;
  font-size: 18px;
}

.MatchEventContent p {
  margin: 2px 0 5px 0;
  font-size: 14px;
  line-height: 1.4;
  color: var(--gray);
  font-family: 'Lato', sans-serif;
}

.MatchEventContent__featured-team {
  display: flex;
  margin-bottom: 8px;
  height: 50px;
  align-items: center;
}

.MatchEventContent__featured-team h3 {
  margin-left: 8px;
  color: var(--gray);
  font-size: 14px;
}

.MatchEventActions {
  margin-left: 8px;
}
.MatchImageForm__upload {
  margin: 16px 0;
}

.ArticleChecklist {
  background: var(--gray-lightest);
  border-radius: 5px;
  padding: 10px;
  margin-top: 8px;
}

.ArticleChecklist__item {
  font-size: 12px;
  margin-bottom: 5px;
}

.ArticleChecklist__item .fa {
  margin-right: 4px;
  width: 12px;
  text-align: center;
}

.ArticleChecklist__item .fa-check {
  color: var(--success);
}

.ArticleChecklist__item .fa-times {
  color: var(--error);
}
.Tabs {
  background: white;
  /* height: 50px; */
  border-top: 1px solid var(--gray-lightest);
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.05);
}

.Tabs__tab-item {
  line-height: 47px;
  display: inline-block;
  border-bottom: 3px solid white;
  font-size: 12px;
  text-transform: uppercase;
  color: #959595;
  font-weight: bold;
  padding: 0 15px;
  margin-right: 0px;
  transition: all 0.3s;
  cursor: pointer;
}

.Tabs__tab-item:hover,
.Tabs__tab-item--active {
  background: #f0f0f0;
  color: #565656;
  border-bottom: 3px solid #202329;
}

@media only screen and (max-width: 576px) {
  .Tabs {
    height: auto;
  }

  .Tabs__tab-item {
    width: 100%;
    text-align: center;
  }
}

.MatchSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -20px;
}

.MatchSummary__match-info strong {
  display: block;
}

.MatchStatus {
  text-align: center;
}

.MatchStatus small {
  display: block;
}

.MatchStatus__status--live {
  color: var(--live);
}

.MatchSummary__toggle div {
  position: relative;
  left: 2px;
}

.MatchSummary__block {
  text-align: center;
}

.MatchSummary__block strong {
  display: block;
}

@media screen and (max-width: 768px) {
  .MatchSummary {
    flex-direction: column;
  }

  .MatchSummary__block, .MatchSummary__match-info, .MatchSummary__toggle, .MatchStatus {
    margin: 8px 0;
    text-align: center;
  }
}
.SelectPool__list-item {
  display: flex;
  border-top: 1px solid var(--gray-lighter);
  padding: 12px;
  background: var(--gray-lightest);
  font-size: 12px;

  align-items: center;
}

.SelectPool__list-item .SelectPool__list-item-label {
  flex: 1 1;
}

.SelectPool__list-item .SelectPool__list-item-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  background: transparent;
  color: var(--accent);
  line-height: normal;
  font-size: 10px;
  cursor: pointer;
}

.SelectPool__empty-state {
  background: var(--gray-lightest);
  text-align: center;
  line-height: 20px;
  padding: 10px;
  font-size: 12px;
  border-radius: 10px;
}

.SelectPool__select {
  margin-top: 16px;
}
.substage-icon {
  height: 50%;
  width: 50%;
}

.cls-1,
.cls-2 {
  fill: #eee;
}

.cls-2 {
  fill-rule: evenodd;
}

.PageNavigator {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.PageNavigator__itens-per-page {
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.PageNavigator__itens-per-page-label {
  margin-right: 8px;
  color: var(--gray-light);
}

.PageNavigator__itens-per-page-link {
  color: var(--dark-gray);
  margin-right: 8px;
  cursor: pointer;
}

.PageNavigator__itens-per-page-link:hover {
  color: var(--accent);
}

.PageNavigator__itens-per-page-link--active {
  color: var(--accent);
}

.PageNavigator__itens-per-page-link:last-child {
  margin-right: 0px;
}

.PageNavigator__page-btns-btn {
  margin-right: 32px;
}

.PageNavigator__page-btns-btn:last-child {
  margin-right: 0px;
}
.Search {
  margin-top: 0px;
  text-align: right;
}

.Search__input {
  margin-right: 8px;
}
.TeamList__SearchBar {
  display: flex;
  justify-content: right;
  margin-bottom: 16px;
}

.TeamList__cancel-delete-team {
  margin-right: 16px;
}
.SelectField__label {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  margin-top: 8px;
  display: block;
}
.TeamForm__input {
  margin-top: -12px;
}

.TeamForm__label {
  font-size: 13px;
  color: var(--accent);
  display: block;
  margin-top: 24px;
}

.TeamForm__submit {
  margin-top: 16px;
}

.TeamForm__existing-logo {
  margin: 24px 0;
  font-size: 12px;
  color: var(--gray)
}
.StreamList__SearchBar {
  display: flex;
  justify-content: right;
  margin-bottom: 16px;
}

.StreamList__cancel-delete-team {
  margin-right: 16px;
}
.TransferForm__card {
  margin-bottom: 16px;
}

.TransferForm__checkbox {
  margin-top: 16px;
}
.RankingForm__team-container {
  margin-bottom: 16px;
  position: relative;
}

.RankingForm__team {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RankingForm__team-checkbox {
  flex: 0 1;
}

.RankingForm__team-dropdown {
  flex: 1 1;
  width: 100%;
  margin: 0 16px;
}

.RankingForm__team-dropdown label {
  font-size: 12px;
  color: var(--gray);
}

.RankingForm__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RankingForm__meta-field {
  flex: 1 1;
}

.RankingForm__meta-field:first-child {
  margin-right: 16px;
}

.RankingForm__observation {
  flex: 1 1;
  margin-right: 16px;
}

.RankingForm__remove-field {
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 3px;
}

.RankingForm__submit {
  margin-top: 16px;
}

@media screen and (max-width: 768px) {
  .RankingForm__team {
    flex-direction: column;
  }

  .RankingForm__team-container:first-child {
    margin-top: 16px;
  }
}
.Sidebar {
  width: 250px;
  height: 100vh;
  background-color: var(--main-dark);
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  box-sizing: border-box;
  overflow-y: scroll;
  padding-bottom: 24px;
}

.Sidebar__logo {
  text-align: center;
  display: block;
  padding: 10px;
  box-sizing: border-box;
  height: 85px;
  border-bottom: 1px solid var(--main);
}

.Sidebar__title {
  color: white;
  display: block;
  font-size: 10px;
}

.Sidebar__logo-img {
  width: 55%;
}

.Sidebar__nav-divider {
  color: var(--accent);
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  margin: 15px;
}

.Sidebar__nav {
  color: var(--gray-lightest);
  display: block;
  background: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  padding: 10px 25px;
  margin-bottom: 1px;
  transition: all 0.2s;
  font-family: 'Fira Sans', sans-serif;
  border-left: 3px solid var(--main-lighter);
  letter-spacing: -0.03em;
}

.Sidebar__nav--mobile-only {
  display: none;
}

.Sidebar__nav:hover,
.Sidebar__nav--active {
  color: white;
  border-left: 10px solid var(--main);
  background: #0084ff; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #0084ff 0%,
    #0058e8 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0084ff', endColorstr='#0058E8',GradientType=1 ); /* IE6-9 */
}

.Sidebar__nav-icon {
  width: 25px;
}

@media screen and (max-width: 768px) {
  .Sidebar {
    position: absolute;
    top: 0;
    width: 90%;
    z-index: 99;
  }

  .Sidebar--hidden {
    display: none;
  }

  .Sidebar__logo-img {
    max-width: 120px;
  }

  .Sidebar__nav--mobile-only {
    display: block;
  }
}

.Logged__container {
  margin-left: 250px;
}

@media screen and (max-width: 769px) {
  .Logged__container {
    margin-left: 0;
  }
}

.ListLiveOddProvider__label {
  color: white;
  padding: 3px 4px;
  font-size: 10px;
  border-radius: 5px;
  margin-left: 3px;
  font-weight: bold;
}

.ListLiveOddProvider__actived-label {
  background: var(--accent);
}

.ListLiveOddProvider__deactived-label {
  background: #808080;
}
