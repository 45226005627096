.substage-icon {
  height: 50%;
  width: 50%;
}

.cls-1,
.cls-2 {
  fill: #eee;
}

.cls-2 {
  fill-rule: evenodd;
}
