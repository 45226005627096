.Tabs {
  background: white;
  /* height: 50px; */
  border-top: 1px solid var(--gray-lightest);
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.05);
}

.Tabs__tab-item {
  line-height: 47px;
  display: inline-block;
  border-bottom: 3px solid white;
  font-size: 12px;
  text-transform: uppercase;
  color: #959595;
  font-weight: bold;
  padding: 0 15px;
  margin-right: 0px;
  transition: all 0.3s;
  cursor: pointer;
}

.Tabs__tab-item:hover,
.Tabs__tab-item--active {
  background: #f0f0f0;
  color: #565656;
  border-bottom: 3px solid #202329;
}

@media only screen and (max-width: 576px) {
  .Tabs {
    height: auto;
  }

  .Tabs__tab-item {
    width: 100%;
    text-align: center;
  }
}
