.MatchSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -20px;
}

.MatchSummary__match-info strong {
  display: block;
}

.MatchStatus {
  text-align: center;
}

.MatchStatus small {
  display: block;
}

.MatchStatus__status--live {
  color: var(--live);
}

.MatchSummary__toggle div {
  position: relative;
  left: 2px;
}

.MatchSummary__block {
  text-align: center;
}

.MatchSummary__block strong {
  display: block;
}

@media screen and (max-width: 768px) {
  .MatchSummary {
    flex-direction: column;
  }

  .MatchSummary__block, .MatchSummary__match-info, .MatchSummary__toggle, .MatchStatus {
    margin: 8px 0;
    text-align: center;
  }
}