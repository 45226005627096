.SelectPool__list-item {
  display: flex;
  border-top: 1px solid var(--gray-lighter);
  padding: 12px;
  background: var(--gray-lightest);
  font-size: 12px;

  align-items: center;
}

.SelectPool__list-item .SelectPool__list-item-label {
  flex: 1;
}

.SelectPool__list-item .SelectPool__list-item-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  background: transparent;
  color: var(--accent);
  line-height: normal;
  font-size: 10px;
  cursor: pointer;
}

.SelectPool__empty-state {
  background: var(--gray-lightest);
  text-align: center;
  line-height: 20px;
  padding: 10px;
  font-size: 12px;
  border-radius: 10px;
}

.SelectPool__select {
  margin-top: 16px;
}