.Login {
  background: var(--main-dark);
  height: 100vh;
  padding: 60px;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
}

.Login__box {
  width: 100%;
  border-radius: 20px;
  background: var(--main);
  box-shadow: 0 8px 20px 0 rgba(32, 35, 41, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login__form {
  padding: 30px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 8px 20px 0 rgba(32, 35, 41, 0.6);
  width: 500px;
}

.Dashboard__title {
  font-family: 'Segoe UI';
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
}

.Login__form-logo {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.Login__form-sentence {
  display: block;
  margin-top: 16px;
  color: var(--gray-light);
  font-style: italic;
}

.Login__steam-btn {
  display: block;
  margin-top: 16px;
}
