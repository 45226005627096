.EventForm__card {
  margin-bottom: 16px;
}

.EventForm__media-btn {
  margin-right: 8px;
}

.EventForm__field-icon-container {
  display: flex;
  align-items: center;
}

.EventForm__field-icon-container div + div {
  margin-left: 16px;
}

.EventForm__field-icon-container .fa {
  margin-right: 8px;
  position: relative;
  top: 13px;
}

.EventForm__label-presets {
  display: flex;
  flex-wrap: wrap;
}

.EventForm__label-presets button {
  margin-right: 8px;
}