.Modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0;
}

/* Modal__backdrop transitions */
.Modal-appear.Modal__backdrop {
  opacity: 0;
}

.Modal-appear.Modal-appear-active.Modal__backdrop {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.Modal-exit.Modal__backdrop {
  opacity: 1;
}

.Modal-exit.Modal-exit-active.Modal__backdrop {
  opacity: 0;
  transition: opacity 400ms ease-in;
}
/* Modal__backdrop transitions END */

.Modal__window {
  /* margin: auto; */
  z-index: 3;
  height: auto;
  width: 40%;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 var(--box-shadow);
  opacity: 1;
  background-color: white;
  text-align: left;
}

/* Modal__window transitions */
.Modal-appear .Modal__window {
  opacity: 0;
}

.Modal-appear.Modal-appear-active .Modal__window {
  opacity: 1;
  transition: opacity 400ms ease-in;
}
/* Modal__window transitions END */

.Modal__title {
  padding: 24px;
  font-size: 16px;
  font-weight: bold;
  color: var(--accent);
  border-bottom: 1px solid var(--gray-lighter);
}

.Modal__content {
  padding: 24px;
}

.Modal__footer {
  padding: 24px;
  border-top: 1px solid var(--gray-lighter);
  display: flex;
  justify-content: flex-end;
}