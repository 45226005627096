.MatchEvent {
  position: relative;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
}

.MatchEvent:nth-child(even) {
  background: #f5f5f5;
}

.MatchEventLabel {
  text-align: right;
  padding-right: 20px;
  flex-basis: 15%;
}

.MatchEventLabel p {
  margin: 0;
  line-height: 16px;
  font-size: 20px;
  font-weight: bold;
  color: var(--gray)
}

.MatchEventLabel.MatchEventLabel--custom p {
  font-size: 12px;
}

.MatchEventLabel__map {
  font-size: 12px;
  color: var(--gray-light)
}

.MatchEventContent {
  border-left: 1px solid var(--gray-lighter);
  padding-left: 20px;
  flex-basis: 85%;
}

.MatchEventContent h3 {
  margin: 5px 0 2px 0;
  text-transform: uppercase;
  font-size: 18px;
}

.MatchEventContent p {
  margin: 2px 0 5px 0;
  font-size: 14px;
  line-height: 1.4;
  color: var(--gray);
  font-family: 'Lato', sans-serif;
}

.MatchEventContent__featured-team {
  display: flex;
  margin-bottom: 8px;
  height: 50px;
  align-items: center;
}

.MatchEventContent__featured-team h3 {
  margin-left: 8px;
  color: var(--gray);
  font-size: 14px;
}

.MatchEventActions {
  margin-left: 8px;
}