
.ListLiveOddProvider__label {
  color: white;
  padding: 3px 4px;
  font-size: 10px;
  border-radius: 5px;
  margin-left: 3px;
  font-weight: bold;
}

.ListLiveOddProvider__actived-label {
  background: var(--accent);
}

.ListLiveOddProvider__deactived-label {
  background: #808080;
}