.ArticleChecklist {
  background: var(--gray-lightest);
  border-radius: 5px;
  padding: 10px;
  margin-top: 8px;
}

.ArticleChecklist__item {
  font-size: 12px;
  margin-bottom: 5px;
}

.ArticleChecklist__item .fa {
  margin-right: 4px;
  width: 12px;
  text-align: center;
}

.ArticleChecklist__item .fa-check {
  color: var(--success);
}

.ArticleChecklist__item .fa-times {
  color: var(--error);
}