:root {
  --main: #202329;
  --main-dark: #15171b;
  --main-darker: #0A0B0C;
  --main-lighter: #30353e;

  --accent: #0084ff;
  --accent-lighter: #80C1FF;

  --gray-lightest: #f0f0f0;
  --gray-lighter: #d3d3d3;
  --gray-light: #959595;
  --gray: #565656;
  --gray-dark: #333;
  --gray-darker: #171717;

  --success: #18bc9c;
  --error: #e74c3c;

  --twitch: #6441a5;
  --youtube: #e52d27;
  --hitbox: #99CC00;

  --live: #d2322d;

  --finished: #229e45;
  --finished-light: #F9FCFA;

  --victory: #5cb85c;
  --defeat: #d2322d;
  --draw: #959595;
}

@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700");
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700,900i');

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #202329;
  -webkit-font-smoothing: antialiased;
  background: var(--gray-lightest);
  overflow-y: scroll;
}

a {
  text-decoration: none;
}

.PageContainer {
  padding: 20px;
}

.TableContainer {
  /* overflow: scroll; */
}

@media screen and (max-width: 768px) {
  .TableContainer table {
    min-width: 700px;
  }
}


.AddTop {
  margin-top: 16px;
}

.AddBottom {
  margin-bottom: 16px;
}

.AddRight {
  margin-right: 16px;
}

.TextField {
  margin-top: -12px;
}


.Label {
  font-size: 13px;
  color: var(--accent);
  display: block;
  margin-bottom: 8px;
}