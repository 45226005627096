.RankingForm__team-container {
  margin-bottom: 16px;
  position: relative;
}

.RankingForm__team {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RankingForm__team-checkbox {
  flex: 0;
}

.RankingForm__team-dropdown {
  flex: 1;
  width: 100%;
  margin: 0 16px;
}

.RankingForm__team-dropdown label {
  font-size: 12px;
  color: var(--gray);
}

.RankingForm__meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RankingForm__meta-field {
  flex: 1;
}

.RankingForm__meta-field:first-child {
  margin-right: 16px;
}

.RankingForm__observation {
  flex: 1;
  margin-right: 16px;
}

.RankingForm__remove-field {
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 3px;
}

.RankingForm__submit {
  margin-top: 16px;
}

@media screen and (max-width: 768px) {
  .RankingForm__team {
    flex-direction: column;
  }

  .RankingForm__team-container:first-child {
    margin-top: 16px;
  }
}