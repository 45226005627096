.TeamForm__input {
  margin-top: -12px;
}

.TeamForm__label {
  font-size: 13px;
  color: var(--accent);
  display: block;
  margin-top: 24px;
}

.TeamForm__submit {
  margin-top: 16px;
}

.TeamForm__existing-logo {
  margin: 24px 0;
  font-size: 12px;
  color: var(--gray)
}