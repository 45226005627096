.NewMatchForm__checkbox {
  margin-bottom: 8px;
}

.NewMatchForm__card {
  margin-bottom: 16px;
}

.NewMatchForm__divider {
  margin: 16px 0;
  border-top: 0px;
  border-bottom: 1px solid var(--gray-lighter);
  height: 0px;
}

.NewMatchForm__text-field {
  margin-top: -14px;
}

.NewMatchForm__label {
  font-size: 12px;
  color: var(--gray-light);
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.NewMatchForm__label--dark {
  color: var(--gray-dark);
}

.NewMatchForm__label--button {
  cursor: pointer;
  font-size: 24px;
}

.NewMatchForm__label--button:hover {
  color: var(--error);
}

.NewMatchForm__preset-btn {
  margin-right: 8px;
}

.NewMatchForm__particularity-presets {
  margin-top: 16px;
}

.NewMatchForm__particularity-preset-btn {
  margin-bottom: 6px;
  text-align: left;
  display: block;
}

.NewMatchForm__emptyCard {
  text-align: center;
  color: #666;
}
