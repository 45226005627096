.PageNavigator {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.PageNavigator__itens-per-page {
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.PageNavigator__itens-per-page-label {
  margin-right: 8px;
  color: var(--gray-light);
}

.PageNavigator__itens-per-page-link {
  color: var(--dark-gray);
  margin-right: 8px;
  cursor: pointer;
}

.PageNavigator__itens-per-page-link:hover {
  color: var(--accent);
}

.PageNavigator__itens-per-page-link--active {
  color: var(--accent);
}

.PageNavigator__itens-per-page-link:last-child {
  margin-right: 0px;
}

.PageNavigator__page-btns-btn {
  margin-right: 32px;
}

.PageNavigator__page-btns-btn:last-child {
  margin-right: 0px;
}