.Button {
  width: auto;
  border: 0;
  border-radius: 2px;
  padding: 16px 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Fira Sans', sans-serif;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.01), 0 5px 15px rgba(0, 0, 0, 0.07);
  position: relative;
  top: 0;
}

.Button[disabled] {
  opacity: 0.5;
}

.Button:hover {
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.2);
  top: -2px;
}

.Button__icon {
  display: inline-block;
  margin-right: 8px;
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.Button--outline {
  background: transparent;
  border: 1px solid var(--accent);
  color: var(--accent);
}

.Button--block {
  width: 100%;
}

/* ========================
SKINS
========================= */
.Button--main {
  background: var(--main);
  color: white;
  border-color: var(--main);
}

.Button--accent {
  background: var(--accent);
  color: white;
  border-color: var(--accent);
}

.Button--gray {
  background: var(--gray);
  color: white;
  border-color: var(--gray);
}

.Button--gray.Button--outline {
  background: transparent;
  color: var(--gray);
  border-color: var(--gray);
}

.Button--error {
  background: var(--error);
  color: white;
  border-color: var(--error);
}

.Button--error.Button--outline {
  border-color: var(--error);
  background: transparent;
  color: var(--error);
}

.Button--success {
  background: var(--success);
  color: white;
  border-color: var(--success);
}

/* ========================
Sizes
========================= */
.Button--small {
  padding: 8px;
  font-size: 14px;
}

.Button--micro {
  padding: 4px;
  font-size: 12px;
}

.Button--micro .Button__icon {
  font-size: 14px;
  margin: 0;
  margin-right: 4px;
  height: auto;
  width: auto;
}

.Button--disabled,
.Button[disabled] {
  color: white;
  opacity: 0.5;
  background: var(--gray-light);
  cursor: not-allowed;
}
