.Table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0px 35px rgba(50,50,93,.01), 0 5px 15px rgba(0,0,0,.07);
}

.Table__thead {
  background: var(--gray-lighter);
  box-shadow: inset 0 -5px 8px rgba(0,0,0,0.1);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 18px;
}

.Table__thead tr td {
  padding: 5px;
}

.Table__thead tr td:last-child {
  border-right: 0px;
}

.Table__tbody {
  background: white;
  font-size: 14px;
}

.Table__tbody tr td {
  padding: 10px;
}

.Table__tbody tr {
  border-bottom: 1px solid var(--gray-lighter);
}

.Table__tbody tr:last-child {
  border-bottom: 0px;
}