
.Alert {
  position: relative;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  align-items: center;
  display: flex;
  z-index: -1;
  box-shadow: 3px 0 6px rgba(0,0,0,0.1);
  white-space: pre-wrap;
}

.Alert__Warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.Alert__Warning-Icon {
  margin-right: 8px;
  font-size: 1.5rem;
  line-height: 1;
}

.Alert__Info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.Alert__Error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.Alert__Icon {
  margin-right: 8px;
  font-size: 1.5rem;
  line-height: 1;
}

@media screen and (max-width: 1150px) {
  .Alert {
      display: block;
      font-size: 12px;
      line-height: 1.5;
      padding: 0.75rem 0.75rem;
  }

  .Alert__Icon {
      font-size: 1rem;
      margin-right: 4px;
  }
}