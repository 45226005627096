.LoadMask__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.75);
  overflow: auto;
  color: white;
}

/* LoadMask__backdrop transitions */
.LoadMask-appear.LoadMask__backdrop {
  opacity: 0;
}

.LoadMask-appear.LoadMask-appear-active.LoadMask__backdrop {
  opacity: 1;
  transition: opacity 100ms ease-in;
}
/* LoadMask__backdrop transitions END */