.MatchList__SearchBar {
  display: flex;
  justify-content: right;
  margin-bottom: 16px;
  align-items: center;
}

.MatchList__SearchBar small {
  display: block;
  margin-right: 8px;
}

.MatchList__label {
  color: white;
  padding: 3px 4px;
  font-size: 10px;
  border-radius: 5px;
  margin-left: 3px;
  font-weight: bold;
}

.MatchList__featured-label {
  background: var(--accent);
}

.MatchList__hidded-label {
  background: #808080;
}

.MatchList__finished-label {
  background: var(--finished);
}

.MatchList__pbp-label {
  background: #d69d02;
}

.MatchList__live-label {
  background: var(--live);
}

.MatchList__nostreamed-label {
  background: #6f6785;
}

.MatchList__istba-label {
  background: #856767;
}

.MatchList__vzone-label {
  background: #101822;
}


.MatchList__football-label {
  background: #2b9e4e;
}