.Card {
  background: white;
  box-shadow: 0 0px 35px rgba(50,50,93,.01), 0 5px 15px rgba(0,0,0,.07);
  padding: 20px;
}

.Card--no-padding {
  padding: 0px;
}

.Card__title {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 8px;
  padding-left: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--gray-lighter);
  position: relative;
}

.Card__title:before {
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: var(--accent);
  position: absolute;
  display: block;
  top: 6px;
  left: 2px;
}

.Card--no-padding .Card__title {
  padding: 16px;
  padding-left: 32px;
}

.Card--no-padding .Card__title:before {
  top: 22px;
  left: 16px;
}