.Sidebar {
  width: 250px;
  height: 100vh;
  background-color: var(--main-dark);
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  box-sizing: border-box;
  overflow-y: scroll;
  padding-bottom: 24px;
}

.Sidebar__logo {
  text-align: center;
  display: block;
  padding: 10px;
  box-sizing: border-box;
  height: 85px;
  border-bottom: 1px solid var(--main);
}

.Sidebar__title {
  color: white;
  display: block;
  font-size: 10px;
}

.Sidebar__logo-img {
  width: 55%;
}

.Sidebar__nav-divider {
  color: var(--accent);
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  margin: 15px;
}

.Sidebar__nav {
  color: var(--gray-lightest);
  display: block;
  background: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  padding: 10px 25px;
  margin-bottom: 1px;
  transition: all 0.2s;
  font-family: 'Fira Sans', sans-serif;
  border-left: 3px solid var(--main-lighter);
  letter-spacing: -0.03em;
}

.Sidebar__nav--mobile-only {
  display: none;
}

.Sidebar__nav:hover,
.Sidebar__nav--active {
  color: white;
  border-left: 10px solid var(--main);
  background: #0084ff; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #0084ff 0%,
    #0058e8 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #0084ff 0%,
    #0058e8 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #0084ff 0%,
    #0058e8 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0084ff', endColorstr='#0058E8',GradientType=1 ); /* IE6-9 */
}

.Sidebar__nav-icon {
  width: 25px;
}

@media screen and (max-width: 768px) {
  .Sidebar {
    position: absolute;
    top: 0;
    width: 90%;
    z-index: 99;
  }

  .Sidebar--hidden {
    display: none;
  }

  .Sidebar__logo-img {
    max-width: 120px;
  }

  .Sidebar__nav--mobile-only {
    display: block;
  }
}
