.Header {
  background: white;
  top: 0;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
}

.Header__toggle-menu {
  display: none;
}

.Header__page-name {
  color: var(--accent);
  margin: 0;
  flex-grow: 2;
}

.Header__actions {
  margin: 0 24px;
}

.Header__login-info {
  display: flex;
  align-items: center;
}

.Header__logout-icon {
  margin-left: 8px;
  color: var(--gray);
  cursor: pointer;
}

.Header__login-info-avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.Header__login-info-username {
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  top: -1px;
}

@media screen and (max-width: 768px) {
  .Header__toggle-menu {
    display: inline-block;
    cursor: pointer;
    margin-right: 16px;
  }
}