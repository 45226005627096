.TwitchClip__not-visible {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  color: white;
  font-size: 80px;
  cursor: pointer;
  background-position: center;
  transition: all 0.2s;
}

.TwitchClip__not-visible .fa {
  transition: all 0.2s;
}

.TwitchClip__not-visible:hover .fa {
  transform: scale(1.2);
  text-shadow: 0px 0px 50px rgba(0,0,0,0.9);
}